import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Get, Post } from "../../api";
import FeedList from "../../Components/FeedList";
import Loader from "../../Components/Loader";
import SideBar from "../../Components/SideBar";
import UserProfile from "../../Components/UserProfile";

const Profile = () => {
  const { user, token } = useSelector((state) => state.user);

  const [thisUser, setThisUser] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [docCount, setDocCount] = useState(0);

  const { userId } = useParams();

  const [showComments, setShowComments] = useState(false);
  const [urlUser, setUrlUser] = useState(userId || null);
  const [loading, setLoading] = useState(true);

  const commentHandler = () => {};
  const shareHandler = () => {};
  //   const showComments = () => {};

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { data } = await Get(
        `api/v2/app/feeds/user-feeds/${urlUser || user._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setThisUser(data.user);
      setFeeds(data.feeds);
      setDocCount(data.docCount);
      setLoading(false);
      //   console.log(data);
    })();
  }, [user, token, urlUser]);

  const followHandler = async () => {
    setThisUser((prevState) => ({
      ...prevState,
      followed: !prevState.followed,
    }));
    const { data } = await Post(
      { followingId: userId },
      "api/v2/app/user/follow",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return (
    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        {/* <div className="my-profile card">
          <div className="page-head mb-4">
            <div className="d-flex align-items-center">
              <a href="javascript:;" className="btn btn-icon">
                <img src="/images/arrow-left.svg" />
              </a>
              <a href="javascript:;" className="btn btn-icon ms-auto">
                <img src="/images/plus.svg" />
              </a>
            </div>
          </div>
          <div className="banner-profile">
            <img src="/images/3.jpg" />
          </div>
          <div className="my-profile-info">
            <div className="big-profile">
              <figure className="avatar">
                <img src="/images/user.jpg" />
              </figure>
              <img
                src="/images/check-verified-02.svg"
                className="verified-tag"
              />
            </div>
            <a href="javascript:;" className="btn btn-icon">
              <img src="/images/edit-03.svg" />
            </a>
            <div className="my-profile-name text-center">
              <h4>Joshua Hughes</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div className="profile-statics">
              <a href="javascript:;">
                <h4>145</h4>
                <span>Posts</span>
              </a>
              <a href="javascript:;">
                <h4>4532</h4>
                <span>Followers</span>
              </a>
              <a href="javascript:;">
                <h4>546</h4>
                <span>Following</span>
              </a>
            </div>
          </div>
        </div> */}

        {loading ? (
          <Loader />
        ) : (
          <>
            <UserProfile
              thisUser={thisUser}
              postCount={docCount}
              followHandler={followHandler}
            />

            {feeds.length > 0 ? (
              feeds.map((feed, index) => (
                <FeedList
                  key={index}
                  feed={feed}
                  feeds={feeds}
                  setFeeds={setFeeds}
                  commentHandler={commentHandler}
                  shareHandler={shareHandler}
                  showComments={showComments}
                />
              ))
            ) : (
              <div class="post-wrap-outer">
                <div class="card no-post">
                  <div class="no-post-in text-center">
                    <figure>
                      <img src="/images/not-post.svg" alt="..." />
                    </figure>
                    <h4>No posts yet!</h4>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
