import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-start">
            © 2023 Ootky. All rights reserved.
          </div>
          <div className="col-md-6 text-end">
            <a href="javascript:;">Terms & Conditions</a>
            <a href="javascript:;">Privacy Policy</a>
            <a href="javascript:;">Contact Us</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
