import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SideBar from "../../Components/SideBar";
import { logout } from "../../store/reducers/user";

const Setting = () => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("user");
    // navigate("/login");
    dispatch(logout());
  };

  return (
    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        <div className="page-head mb-4">
          <div className="d-flex align-items-center">
            <a href="javascript:;" className="btn btn-icon">
              <img src="/images/arrow-left.svg" />
            </a>
            <h4 className="ms-3">Settings</h4>
          </div>
        </div>
        <div className="profile-links card">
          <ul>
            <li>
              <Link to="/edit-profile" className="d-flex">
                {/* <a href="javascript:;" className="d-flex"> */}
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/edit-04.svg" />
                  </div>
                  <span>Edit profile</span>
                </div>
                <div className="link-right">
                  <img src="/images/chevron-right.svg" />
                </div>
                {/* </a> */}
              </Link>
            </li>
            <li>
              <Link to="/verification-account" className="d-flex">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/user-check-01.svg" />
                  </div>
                  <span>Apply for verified Account</span>
                </div>
                <div className="link-right">
                  <img src="/images/chevron-right.svg" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/feedback" className="d-flex">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/message-dots-square.svg" alt="..." />
                  </div>
                  <span>Feedback</span>
                </div>
                <div className="link-right">
                  <img src="/images/chevron-right.svg" />
                </div>
              </Link>
            </li>
            {/* <li>
              <a href="javascript:;" className="d-flex">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/marker-pin-01.svg" />
                  </div>
                  <span>Location</span>
                </div>
                <div className="link-right">
                  <div className="link-right">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </a>
            </li> */}
            {/* <li>
              <a href="javascript:;" className="d-flex">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/bell-03.svg" />
                  </div>
                  <span>Notification</span>
                </div>
                <div className="link-right">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </a>
            </li> */}
            <li>
              <Link to="/cms?page=Terms-Conditions" className="d-flex">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/file-06.svg" />
                  </div>
                  <span>Terms & Conditions </span>
                </div>
                <div className="link-right">
                  <img src="/images/chevron-right.svg" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/cms?page=Privacy Policy" className="d-flex">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/file-02.svg" />
                  </div>
                  <span>Privacy Policy</span>
                </div>
                <div className="link-right">
                  <img src="/images/chevron-right.svg" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="d-flex">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/message-chat-square.svg" alt="..." />
                  </div>
                  <span>Contact us</span>
                </div>
                <div className="link-right">
                  <img src="/images/chevron-right.svg" />
                </div>
              </Link>
            </li>
            <li>
              <p onClick={logoutHandler} className="d-flex custom-logout-btn">
                <div className="link-left">
                  <div className="s-icon">
                    <img src="/images/log-out-01.svg" />
                  </div>
                  <span>Logout</span>
                </div>
                <div className="link-right">
                  <img src="/images/chevron-right.svg" />
                </div>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Setting;
