import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";
// import Carousel from "react-bootstrap/Carousel";

const ReactCarousel = ({ media }) => {
  return (
    <div className="item">
      {/* <Link data-fancybox="gallery" to={`${media.path}`}> */}
      <div className="post-images-in">
        {media.type.toLowerCase() === "image" ? (
          <img src={`${BASE_URL}${media.path}`} alt="..." />
        ) : (
          <video controls>
            <source src={`${BASE_URL}${media.path}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {/* </Link> */}
    </div>
  );
  // return (
  //   <Carousel.Item>
  //     {media.type.toLowerCase() === "image" ? (
  //       <img src={`${BASE_URL}${media.path}`} alt="..." />
  //     ) : (
  //       <video controls>
  //         <source src={`${BASE_URL}${media.path}`} type="video/mp4" />
  //         Your browser does not support the video tag.
  //       </video>
  //     )}
  //   </Carousel.Item>
  // );
};

export default ReactCarousel;
