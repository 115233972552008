import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Get } from "../../api";
import { BASE_URL } from "../../constants";

const SharedFeed = () => {
  const [feed, setFeed] = useState({});
  const [recentFeed, setRecentFeed] = useState([]);
  const [loader, setLoader] = useState(true);

  const { title, slug } = useParams();

  const dateFormat = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const dateTimestamp = new Date(date);
    return `${
      monthNames[dateTimestamp.getMonth()]
    } ${dateTimestamp.getDate()} ${dateTimestamp.getFullYear()}`;
  };

  useEffect(() => {
    setLoader(true);
    (async () => {
      const { data } = await Get(`api/v2/feed/shared/${slug}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setFeed(data.feed);
      setLoader(false);
    })();
  }, [slug]);

  useEffect(() => {
    setLoader(true);
    (async () => {
      const { data } = await Get(`api/v2/feed/recent/${slug}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoader(false);
      setRecentFeed(data.feeds);
    })();
  }, [slug]);

  return (
    <>
      {!loader && feed && feed.title && (
        <Helmet>
          <meta name="title" content={`${feed.title}`} />
          <title>{feed.title}</title>
          {feed.description && (
            <meta name="description" content={`${feed.description}`} />
          )}
        </Helmet>
      )}

      {loader ? (
        <>loading</>
      ) : (
        <>
          {feed && feed.title && (
            <div className="row">
              <div className="col-md-8">
                <div className="post-outer">
                  <div className="post-inner-box">
                    <Link
                      to={`/user/${feed.fullName.replace(" ", "_")}/${
                        feed.userId
                      }`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="post-head">
                        <figure>
                          {feed.userImage ? (
                            <img
                              src={`${BASE_URL}${feed.userImage}`}
                              alt="..."
                            />
                          ) : (
                            <img src="/images/default.jpeg" alt="..." />
                          )}
                        </figure>
                        <figcaption>
                          <div className="user-name-info">
                            <h4>{feed.fullName}</h4>
                            <p>{dateFormat(feed.createdAt)}</p>
                          </div>
                        </figcaption>
                      </div>
                    </Link>
                    <div className="post-video-photo">
                      <div
                        id="carouselExampleControls"
                        className="carousel slide"
                        data-bs-ride="carousel"
                        data-interval="false"
                      >
                        <div className="carousel-inner">
                          <Carousel
                            navButtonsAlwaysVisible={true}
                            fullHeightHover={false}
                            indicators={false}
                          >
                            {feed.media.length &&
                              feed.media.map((media, index) => (
                                <div className="carousel-item" key={index}>
                                  {media.type.toLowerCase() === "image" ? (
                                    <img
                                      src={`${BASE_URL}${media.path}`}
                                      className="d-block w-100"
                                      alt="..."
                                    />
                                  ) : (
                                    <video controls autoPlay>
                                      <source
                                        src={`${BASE_URL}${media.path}`}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )}
                                </div>
                              ))}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                    <div className="post-desc">
                      <h4>{feed.title}</h4>
                      <p>{feed.description}</p>
                    </div>
                  </div>
                </div>
              </div>
              {!loader && recentFeed.length > 0 && (
                <div className="col-md-4">
                  <div className="side-post mb-3">
                    <h4>Recent posts</h4>
                  </div>
                  <div className="side-post-box">
                    {recentFeed.map((feed, index) => (
                      <div className="side-post-box-in" key={index}>
                        <Link to={`/post/${feed.linkTitle}/${feed.slug}`}>
                          {feed.media.length > 0 ? (
                            <figure>
                              {feed.media.length > 0 ? (
                                feed.media[0].type === "image" ? (
                                  <img
                                    src={`${BASE_URL}${feed.media[0].path}`}
                                    alt="..."
                                  />
                                ) : feed.media[0].type === "video" ? (
                                  <video controls autoPlay>
                                    <source
                                      src={`${BASE_URL}${feed.media[0].path}`}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : null
                              ) : null}
                            </figure>
                          ) : null}

                          <figcaption>
                            <h5>{feed.title}</h5>
                            <p>{feed.description.slice(0, 50)}...</p>
                          </figcaption>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SharedFeed;
