import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../store/reducers/user";

const SideBar = () => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("user");
    // navigate("/login");
    dispatch(logout());
  };

  const { status } = useSelector((state) => state.header);

  const location = useLocation();

  return (
    <div className={status ? "sidebar active" : "sidebar"}>
      <ul>
        <li className={location.pathname === "/" ? "active" : ""}>
          <Link to="/">
            <div className="s-icon">
              <img src="/images/feeds.svg" alt="..." />
            </div>
            <span>Feeds</span>
          </Link>
        </li>
        <li className={location.pathname === "/profile" ? "active" : ""}>
          <Link to="/profile">
            <div className="s-icon">
              <img src="/images/profile.svg" alt="..." />
            </div>
            <span>Profile</span>
          </Link>
        </li>
        <li className={location.pathname === "/notification" ? "active" : ""}>
          <Link to="/notification">
            <div className="s-icon">
              <img src="/images/notification.svg" alt="..." />
            </div>
            <span>Notifications</span>
          </Link>
        </li>
        <li className={location.pathname === "/settings" ? "active" : ""}>
          <Link to="/settings">
            <div className="s-icon">
              <img src="/images/setting.svg" alt="..." />
            </div>
            <span>Settings</span>
          </Link>
        </li>
        <li>
          <div className="logout" onClick={logoutHandler}>
            <div className="s-icon">
              <img src="/images/logout.svg" alt="..." />
            </div>
            <span>Logout</span>
          </div>
        </li>
      </ul>
      <div className="download-link">
        <a href="javascript:;">
          <img src="/images/apple.svg" />
        </a>
        <a href="javascript:;">
          <img src="/images/google.svg" />
        </a>
      </div>
    </div>
  );
};

export default SideBar;
