// import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import AuthRoutes from "./routes/AuthRoutes";
// import Layout from "./Components/Layout";
// import ChangePassword from "./Pages/Auth/ChangePassword";
// import ForgotPassword from "./Pages/Auth/ForgotPassword";
// import Login from "./Pages/Auth/Login";
// import Register from "./Pages/Auth/Register";
// import Verification from "./Pages/Auth/Verification";
// import Home from "./Pages/Main/Home";
import Routes from "./routes/index";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import { BASE_URL } from "./constants";
import { setSocket } from "./store/reducers/socket";

// import MainRoutes from "./routes/MainRoutes";

function App() {
  // const { token } = useSelector((state) => state.user);
  // let isLoggedIn = false;
  // if (token) {
  //   isLoggedIn = true;
  // }

  // const router = createBrowserRouter([
  //   AuthRoutes(isLoggedIn),
  //   MainRoutes(isLoggedIn),
  // ]);

  // const { token } = useSelector((state) => state.user);
  // let isLoggedIn = false;
  // if (token) {
  //   isLoggedIn = true;
  // }

  // console.log(MainRoutes(isLoggedIn));

  // console.log([MainRoutes(isLoggedIn), AuthRoutes(isLoggedIn)]);

  // const router = createBrowserRouter([
  //   MainRoutes(isLoggedIn),
  //   AuthRoutes(isLoggedIn),
  // ]);

  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io(BASE_URL);
    dispatch(setSocket(socket));
    return () => {
      socket.close();
    };
  }, [dispatch]);

  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  );

  // return <Routes />;
}

export default App;
