import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Post } from "../../api";
import SideBar from "../../Components/SideBar";

const VerifiedRequest = () => {
  const { user, token } = useSelector((state) => state.user);

  const [req, setReq] = useState(false);
  const [exists, setExists] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await Get(`api/v2/app/request/${user._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.status) {
        setReq(data.exists);
        setExists(data.exists);
      }
    })();
  }, [user, token]);

  const clickHandler = async () => {
    if (!exists) {
      const { data } = await Post({ type: "verified" }, `api/v2/app/request`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.status) {
        toast.success(data.message);
      }
    } else {
      toast.success("Already applied for verification");
    }
  };

  return (
    <div class="site-container">
      <div class="container">
        <div class="container-relative">
          <SideBar />
          <div class="in-container">
            <div class="page-head mb-4">
              <div class="d-flex align-items-center">
                <Link to="/settings" class="btn btn-icon">
                  <img src="/images/arrow-left.svg" />
                </Link>
                <h4 class="ms-3">Apply For Verified Account</h4>
              </div>
            </div>
            <div class="card verified-wrap">
              <div class="verified-wrap-in">
                <div class="verified-head mb-3">
                  <figure class="mb-3">
                    <img src="/images/verification.svg" />
                  </figure>
                  <figcaption>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </figcaption>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={req}
                    checked={req}
                    name="checkReq"
                    // id="checkReq"
                    disabled={exists}
                    onChange={(e) => setReq(!req)}
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" htmlFor="flexCheckDefault">
                    Apply for the verification badge
                  </label>
                </div>
                <button
                  disabled={exists}
                  class="btn btn-blue w-100"
                  onClick={clickHandler}
                >
                  Send request
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedRequest;
