import React from "react";
import { toast } from "react-toastify";
import ImagePreview from "./ImagePreview";
import VideoPreview from "./VideoPreview";

const MediaPreview = ({ postState, setPostState }) => {
  if (
    (postState.images.length === 1 && postState.video.length === 0) ||
    (postState.video.length === 1 && postState.images.length === 0)
  ) {
    return (
      <div class="w-100 w-50-h">
        <div class="upload-preview-box">
          {/* <img src="/images/1.jpg" />
                    <button>
                      <i class="ri-close-line"></i>
                    </button> */}
          {postState.images.length === 1 ? (
            <ImagePreview file={postState.images[0]} />
          ) : (
            <VideoPreview file={postState.video[0]} />
          )}
        </div>
      </div>
    );
  } else if (
    (postState.images.length === 2 && postState.video.length === 0) ||
    (postState.images.length === 1 && postState.video.length === 1)
  ) {
    return (
      <>
        <div class="w-50 w-50-h">
          <div class="upload-preview-box">
            <ImagePreview file={postState.images[0]} />
            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
        </div>
        <div class="w-50 w-50-h">
          <div class="upload-preview-box">
            {postState.video.length === 1 ? (
              <VideoPreview file={postState.video[0]} />
            ) : (
              <ImagePreview file={postState.images[1]} />
            )}

            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
        </div>
      </>
    );
  } else if (
    (postState.images.length === 3 && postState.video.length === 0) ||
    (postState.images.length === 2 && postState.video.length === 1)
  ) {
    return (
      <>
        <div class="w-50 w-50-h">
          <div class="upload-preview-box">
            <ImagePreview file={postState.images[0]} />
            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
        </div>
        <div class="w-50">
          <div class="upload-preview-box">
            <ImagePreview file={postState.images[1]} />
            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
          <div class="upload-preview-box">
            {postState.video.length === 1 ? (
              <VideoPreview file={postState.video[0]} />
            ) : (
              <ImagePreview file={postState.images[2]} />
            )}

            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
        </div>
      </>
    );
  } else if (
    (postState.images.length === 4 && postState.video.length === 0) ||
    (postState.images.length === 3 && postState.video.length === 1)
  ) {
    return (
      <>
        <div class="w-50">
          <div class="upload-preview-box">
            <ImagePreview file={postState.images[0]} />
            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
          <div class="upload-preview-box">
            <ImagePreview file={postState.images[1]} />
            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
        </div>
        <div class="w-50">
          <div class="upload-preview-box">
            <ImagePreview file={postState.images[2]} />
            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
          <div class="upload-preview-box">
            {postState.video.length === 1 ? (
              <VideoPreview file={postState.video[0]} />
            ) : (
              <ImagePreview file={postState.images[3]} />
            )}
            {/* <button>
                        <i class="ri-close-line"></i>
                      </button> */}
          </div>
        </div>
      </>
    );
  } else if (
    (postState.images.length > 4 && postState.video.length === 0) ||
    (postState.images.length === 4 && postState.video.length === 1)
  ) {
    setPostState((prevState) => ({ ...prevState, images: [], video: [] }));
    return toast.warn("Cannot select more than 4 media");
  }
};

export default MediaPreview;
