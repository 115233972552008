import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Get, Patch } from "../../api";
import Comments from "../../Components/Comments";
import PostActionBar from "../../Components/PostActionBar";
import SideBar from "../../Components/SideBar";
import { BASE_URL } from "../../constants";

const PostDetail = () => {
  const [loading, setLoading] = useState(true);
  const { token, user } = useSelector((state) => state.user);
  const { socket } = useSelector((state) => state.socket);

  const [editingComment, setEditingComment] = useState(false);
  const [editCurrentComment, setEditCurrentComment] = useState({});

  const [feed, setFeed] = useState({});

  const { postId } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await Get(`api/v2/app/feeds/${postId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        setFeed(data.feed);
      }
      setLoading(false);
    })();
  }, [token, setFeed, postId]);

  const likeHandler = async (_feed, _media) => {
    let url = `api/v2/app/feeds/like/${_feed._id}`;

    if (_media) {
      url = `api/v2/app/feeds/like/${_feed._id}/${_media._id}`;
    }

    const { data } = await Patch({}, url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (_feed && !_media) {
      if (data) {
        const newData = [];
        // setIsLikedByUser(feed.likes.includes(user._id.toString()));
        // console.log(isLikedByUser);
        if (feed.likes.includes(user._id.toString())) {
          if (feed._id.toString() === _feed._id.toString()) {
            const obj = { ..._feed };
            obj.likesCount = _feed.likesCount - 1;
            obj.likes = _feed.likes.filter(
              (userId) => user._id.toString() !== userId.toString()
            );
            newData.push(obj);
          } else {
            newData.push(_feed);
          }
        } else {
          if (feed._id.toString() === _feed._id.toString()) {
            const obj = { ..._feed };
            console.log(obj);
            obj.likesCount = obj.likesCount + 1;
            obj.likes.push(user._id);

            newData.push(obj);
          } else {
            newData.push(_feed);
          }
        }
        setFeed(newData[0]);
      }
    } else if (_feed && _media) {
      if (data) {
        // setIsLikedByUser(feed.likes.includes(user._id.toString()));
        // console.log(isLikedByUser);
        if (feed._id.toString() === _feed._id.toString()) {
          const newData = [];
          for (const media of feed.media) {
            if (media._id.toString() === _media._id.toString()) {
              if (
                media.likes.find((_user) => {
                  return _user.userId.toString() === user._id.toString();
                })
              ) {
                // console.log("running");
                const obj = { ...media };
                obj.likesCount = obj.likesCount - 1;
                obj.likes = obj.likes.filter(
                  (_user) => user._id.toString() !== _user.userId.toString()
                );
                newData.push(obj);
              } else {
                const obj = { ...media };
                obj.likesCount = obj.likesCount + 1;
                obj.likes.push({ userId: user._id });

                newData.push(obj);
              }
            } else {
              newData.push(media);
            }
          }
          const formattedFeed = { ...feed, media: newData };
          setFeed(formattedFeed);
        }
      }
    }
  };

  const commentEditHandler = async (comment, setContent) => {
    setEditingComment(true);
    setEditCurrentComment(comment);
    setContent(comment.content);
    // socket.emit("updateComment", { commentId, content });
  };

  // const editPostHandler = async (feedId) => {
  //   handleClickOpen();
  // };

  const addCommentHandler = async (
    feedId,
    content,
    setContent,
    setReply,
    commentId,
    mediaId
  ) => {
    if (editingComment) {
      const values = {
        content,
        commentId: editCurrentComment._id,
      };
      socket.emit("updateComment", values);
      setEditingComment(false);
      setEditCurrentComment({});
    } else {
      const values = {
        feedId,
        content,
        userId: user._id,
      };
      if (commentId) values.commentId = commentId;
      if (mediaId) values.mediaId = mediaId;
      if (socket) {
        socket.emit("createComment", values);
      }
    }
    setContent("");
    setReply("");
  };

  useEffect(() => {
    if (socket) {
      socket.on("updateCommentResponse", (data) => {
        const newData = [];
        if (data.updatedComment.feedId.toString() === feed._id.toString()) {
          // for (const comment of _feed.comments) {
          //   if (data.newComment._id.toString() === comment._id.toString()) {
          //     comment.replies = [...data.newComment.replies].reverse();
          //   }
          // }
          const obj = { ...feed };

          const updatedComments = [];
          for (const comment of obj.comments) {
            if (comment._id.toString() === data.updatedComment._id.toString()) {
              updatedComments.push(data.updatedComment);
            } else {
              updatedComments.push(comment);
            }
          }

          obj.comments = [...updatedComments];

          newData.push(obj);
        } else {
          newData.push(feed);
        }
        setFeed(newData);
      });
    }
  }, [socket, feed]);

  useEffect(() => {
    if (socket) {
      socket.on("getCommentResponse", (data) => {
        // console.log(data);
        // setComments((prevState) => [...prevState, data.newComment]);
        if (data && feed && feed._id) {
          // if(data.mediaId)
          if (!data.newComment.mediaId) {
            console.log(data);
            const newData = [];
            if (data.newComment.feedId.toString() === feed._id.toString()) {
              let isReply = false;
              for (const comment of feed.comments) {
                if (data.newComment._id.toString() === comment._id.toString()) {
                  isReply = true;

                  comment.replies = [...data.newComment.replies].reverse();
                }
              }
              if (!isReply) {
                feed.comments = [...feed.comments, data.newComment];
              }
              const obj = { ...feed };
              newData.push(obj);
            } else {
              newData.push(feed);
            }
            setFeed(newData[0]);
          } else if (data.newComment.mediaId) {
            const newData = [];
            const obj = { ...feed };
            if (data.newComment.feedId.toString() === obj._id.toString()) {
              for (const media of obj.media) {
                if (
                  media._id.toString() === data.newComment.mediaId.toString()
                ) {
                  let isReply = false;
                  for (const comment of media.comments) {
                    if (
                      data.newComment._id.toString() === comment._id.toString()
                    ) {
                      isReply = true;
                      comment.replies = [...data.newComment.replies].reverse();
                    }
                  }
                  if (!isReply) {
                    media.comments = [...media.comments, data.newComment];
                  }
                }
              }
              newData.push(obj);
            } else {
              newData.push(feed);
            }
            setFeed(newData[0]);
          }
        }
      });
    }
  }, [socket, feed]);

  const commentHandler = async (feedId, mediaId) => {
    let url = `api/v2/app/comment/${feedId}`;

    if (mediaId) {
      url = `api/v2/app/comment/${feedId}?mediaId=${mediaId}`;
    }
    const { data } = await Get(url, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!mediaId) {
      const newData = [];
      if (feedId.toString() === feed._id.toString()) {
        const obj = { ...feed };
        obj.comments = [...data.comments];
        obj.commentShow = !obj.commentShow;
        newData.push(obj);
      } else {
        newData.push(feed);
      }
      setFeed(newData[0]);
    } else {
      const newData = [];
      if (feedId.toString() === feed._id.toString()) {
        const newMedia = [];
        const obj = { ...feed };

        for (const media of obj.media) {
          if (media._id.toString() === mediaId.toString()) {
            media.comments = [...data.comments];
            media.commentShow = !media.commentShow;
            newMedia.push(obj);
          } else {
            newMedia.push(obj);
          }
        }
      }
      newData.push(feed);
      setFeed({ ...newData[0] });
    }
  };

  const replyHandler = (feedId, commentId, mediaId) => {
    const newData = [];

    if (!mediaId) {
      if (feedId.toString() === feed._id.toString()) {
        const obj = { ...feed };
        // obj.commentShow = !obj.commentShow;
        // newData.push(obj);
        for (const _comment of obj.comments) {
          // if(comment.replies && comment)
          if (commentId.toString() === _comment._id.toString()) {
            // const obj = { ...feed };
            _comment.showReply = !_comment.showReply;
            // console.log(_comment);
          }
        }
        // console.log(obj);
        newData.push(obj);
      } else {
        newData.push(feed);
      }
    } else if (mediaId) {
      if (feedId.toString() === feed._id.toString()) {
        const obj = { ...feed };
        // obj.commentShow = !obj.commentShow;
        // newData.push(obj);
        const newMedia = [];
        for (const media of obj.media) {
          if (mediaId.toString() === media._id.toString()) {
            for (const _comment of media.comments) {
              // if(comment.replies && comment)
              if (commentId.toString() === _comment._id.toString()) {
                // const obj = { ...feed };
                _comment.showReply = !_comment.showReply;
                // console.log(_comment);
              }
            }
            newMedia.push(media);
          } else {
            newMedia.push(media);
          }
        }
        obj.media = [...newMedia];

        // console.log(obj);
        newData.push(obj);
      } else {
        newData.push(feed);
      }
    }
    setFeed({ ...newData[0] });
  };
  // const deletePostHandler = async (id) => {
  //   const { status } = await Delete(`api/v2/app/feeds/${id}`, {
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   if (status) {
  //     const newData = [...feeds];
  //     const formattedData = newData.filter(
  //       (post) => post._id.toString() !== id.toString()
  //     );
  //     setFeeds([...formattedData]);
  //   }
  // };

  const commentDeleteHandler = async (feedId, commentId) => {
    socket.emit("deleteComment", { commentId });
    // const { status } = await Delete(`api/v2/app/comment/${commentId}`, {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // if (status) {
    //   const newData = [...feeds];
    //   const formattedData = newData.filter(
    //     (post) => post._id.toString() !== id.toString()
    //   );
    //   setFeeds([...formattedData]);
    // }
  };

  useEffect(() => {
    // if(){}
    if (socket) {
      socket.on("deleteCommentResponse", (data) => {
        // setComments((prevState) => [...prevState, data.newComment]);
        console.log(data);
        if (feed && feed._id) {
          if (!data.deletedComment.mediaId) {
            const newData = [];
            if (data.deletedComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }

              const obj = { ...feed };

              obj.comments = obj.comments.filter(
                (comment) => comment._id.toString() !== data.deletedComment._id
              );
              newData.push(obj);
            } else {
              newData.push(feed);
            }
            setFeed({ ...newData[0] });
          } else if (data.deletedComment.mediaId) {
            const newData = [];
            if (data.deletedComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }

              const obj = { ...feed };

              const formattedMedia = [];
              for (let media of obj.media) {
                if (
                  media._id.toString() ===
                  data.deletedComment.mediaId.toString()
                ) {
                  media.comments = media.comments.filter(
                    (comment) =>
                      comment._id.toString() !== data.deletedComment._id
                  );

                  formattedMedia.push(media);
                } else {
                  formattedMedia.push(media);
                }
              }
              obj.media = [...formattedMedia];

              newData.push(obj);
            } else {
              newData.push(feed);
            }
            console.log(newData);
            setFeed({ ...newData[0] });
          }
        }
      });
    }
  }, [socket, feed]);

  const deleteReplyHandler = async (commentId, replyId) => {
    socket.emit("deleteReply", { commentId, replyId });
    // const { status } = await Delete(`api/v2/app/comment/${commentId}`, {
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    // if (status) {
    //   const newData = [...feeds];
    //   const formattedData = newData.filter(
    //     (post) => post._id.toString() !== id.toString()
    //   );
    //   setFeeds([...formattedData]);
    // }
  };

  useEffect(() => {
    if (socket) {
      socket.on("deleteReplyResponse", (data) => {
        console.log(data);
        if (data && feed && feed._id) {
          const newData = [];
          if (!data.findComment.mediaId) {
            if (data.findComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }
              const obj = { ...feed };
              // obj.comments = obj.comments.filter(
              //   (comment) => comment._id.toString() !== data.deletedComment._id
              // );

              for (const _comment of obj.comments) {
                if (
                  _comment._id.toString() === data.findComment._id.toString()
                ) {
                  // const replies = [];
                  const tempReplies = [];
                  for (const reply of _comment.replies) {
                    for (const _reply of data.findComment.replies) {
                      if (reply._id.toString() === _reply._id.toString()) {
                        tempReplies.push(reply);
                      }
                    }
                  }
                  _comment.replies = [...tempReplies];
                }
              }

              newData.push(obj);
            } else {
              newData.push(feed);
            }
          } else {
            if (data.findComment.feedId.toString() === feed._id.toString()) {
              // let isReply = false;
              // for (const comment of _feed.comments) {
              //   if (data.newComment._id.toString() === comment._id.toString()) {
              //     comment.replies = [...data.newComment.replies].reverse();
              //   }
              // }
              const obj = { ...feed };
              // obj.comments = obj.comments.filter(
              //   (comment) => comment._id.toString() !== data.deletedComment._id
              // );
              for (const media of obj.media) {
                for (const _comment of media.comments) {
                  if (
                    _comment._id.toString() === data.findComment._id.toString()
                  ) {
                    // const replies = [];
                    const tempReplies = [];
                    for (const reply of _comment.replies) {
                      for (const _reply of data.findComment.replies) {
                        if (reply._id.toString() === _reply._id.toString()) {
                          tempReplies.push(reply);
                        }
                      }
                    }
                    _comment.replies = [...tempReplies];
                  }
                }
              }

              newData.push(obj);
            } else {
              newData.push(feed);
            }
          }
          setFeed({ ...newData[0] });
        }
      });
    }
  }, [socket, feed]);

  return (
    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        <div className="post-wrap card">
          <div className="post-hd">
            <div className="post-profile">
              <figure className="avatar">
                <a href="javascript:;">
                  {feed && feed.userImage ? (
                    <img src={`${BASE_URL}${feed.userImage}`} alt="..." />
                  ) : (
                    <img src="/images/default.jpeg" alt="..." />
                  )}
                </a>
              </figure>
              <figcaption>
                <div>
                  <h4>
                    <Link href="javascript:;">{feed.user}</Link>
                  </h4>
                  <span>{moment(feed.createdAt).fromNow()}</span>
                </div>
              </figcaption>
            </div>
            <div className="post-description">
              <h4>{feed.title}</h4>
              <p>{feed.description}</p>
            </div>
          </div>
          <div className="post-images">
            <div className="slider-1">
              <div className="owl-carousel owl-theme">
                <div className="item">
                  <a
                    data-fancybox="gallery"
                    href="https://images.pexels.com/photos/14296202/pexels-photo-14296202.jpeg"
                  >
                    <div className="post-images-in">
                      <img
                        src="https://images.pexels.com/photos/14296202/pexels-photo-14296202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="..."
                      />
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a data-fancybox="gallery" href="assets/images/2.jpg">
                    <div className="post-images-in">
                      <img src="assets/images/2.jpg" />
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a data-fancybox="gallery" href="assets/images/video.mp4">
                    <div className="post-images-in">
                      <video controls>
                        <source
                          src="assets/images/video.mp4"
                          type="video/mp4"
                        />
                        <source src="movie.ogg" type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <PostActionBar
            feed={feed}
            likeHandler={likeHandler}
            commentHandler={commentHandler}
          />
          {feed.commentShow && (
            <Comments
              feedId={feed._id}
              image={user.image}
              addCommentHandler={addCommentHandler}
              comments={[...feed.comments].reverse()}
              replyHandler={replyHandler}
              commentDeleteHandler={commentDeleteHandler}
              deleteReplyHandler={deleteReplyHandler}
              commentEditHandler={commentEditHandler}
              editingComment={editingComment}
            />
          )}
        </div>

        {feed.media &&
          feed.media.length > 0 &&
          feed.media.map((media, index) => (
            <div className="post-wrap card" key={index}>
              <div className="post-images">
                <img src={`${BASE_URL}${media.path}`} alt="..." />
              </div>
              <PostActionBar
                feed={feed}
                likeHandler={likeHandler}
                media={media}
                commentHandler={commentHandler}
              />
              {media.commentShow && (
                <Comments
                  feedId={feed._id}
                  mediaId={media._id}
                  image={user.image}
                  addCommentHandler={addCommentHandler}
                  comments={[...media.comments].reverse()}
                  replyHandler={replyHandler}
                  commentDeleteHandler={commentDeleteHandler}
                  deleteReplyHandler={deleteReplyHandler}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PostDetail;
