import React, { useEffect, useState } from "react";
import { Get, Patch } from "../../api";
import SideBar from "../../Components/SideBar";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FeedList from "../../Components/FeedList";
import CreatePost from "../../Components/CreatePost";
import Loader from "../../Components/Loader";

const Home = () => {
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [isLikedByUser, setIsLikedByUser] = useState(false);

  // const {user} = use
  // let isLikedByUser = [];
  const { token, user } = useSelector((state) => state.user);

  const shareHandler = () => {};

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await Get(`api/v2/app/feeds`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        setFeeds(data.feeds);
      }
      setLoading(false);
    })();
  }, [token, setFeeds]);

  console.log(feeds);

  return (
    // <div className="site-container">
    //   <div className="container">

    <div className="container-relative">
      <SideBar />
      <div className="in-container">
        <CreatePost />
        {loading ? (
          <Loader />
        ) : (
          <>
            {feeds.length > 0 ? (
              feeds.map((feed, index) => (
                <FeedList
                  key={index}
                  feed={feed}
                  feeds={feeds}
                  setFeeds={setFeeds}
                  shareHandler={shareHandler}
                />
              ))
            ) : (
              <div class="post-wrap-outer">
                <div class="card no-post">
                  <div class="no-post-in text-center">
                    <figure>
                      <img src="/images/not-post.svg" alt=".." />
                    </figure>
                    <h4>No posts yet!</h4>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
