import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import { Formik } from "formik";
import * as Yup from "yup";
import { Get, Patch, Post } from "../../api";
import { useSelector } from "react-redux";
import ImagePreview from "../../Components/ImagePreview";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import { Link } from "react-router-dom";

const EditProfile = () => {
  const [thisUser, setThisUser] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [image, setImage] = useState("");
  const [location, setLocation] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [city, setCity] = useState([]);
  const [loader, setLoader] = useState(true);

  const { user, token } = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const { data } = await Get(`api/v2/app/user/viewProfile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setThisUser(data.user);

      setLoader(false);
    })();
  }, [user, token]);

  useEffect(() => {
    if (currentLocation) {
      (async () => {
        const { data } = await Get(`api/v2/app/location/${currentLocation}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setCity(data.location.city);
      })();
    }
  }, [currentLocation]);

  useEffect(() => {
    if (!loader) {
      (async () => {
        const response = await Get(`api/v2/app/location`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.locations.length > 0) {
          setLocation([...response.data.locations]);
          // setCurrentLocation(response.data.locations[0]._id);

          if (
            thisUser &&
            thisUser.location.stateId &&
            thisUser.location.cityId
          ) {
            const userLocation = response.data.locations.filter(
              (location) =>
                location._id.toString() === thisUser.location.stateId.toString()
            );
            console.log(userLocation);

            setCity([...userLocation[0].city]);
          } else {
            console.log();
            if (
              response.data.locations[0].city &&
              response.data.locations[0].city.length > 0
            ) {
              setCity([...response.data.locations[0].city]);
            }
          }
        }
      })();
    }
  }, [user, token, thisUser, loader]);

  useEffect(() => {
    (async () => {
      setLoader(true);

      // setThisUser(data.user);
    })();
  }, [user, token]);

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await Get(`api/v2/app/user/viewProfile`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     setThisUser(data.user);
  //   })();
  // }, [user, token]);

  // const handleImageChange = (e) => {
  //   console.log(e.target.files[0]);
  // };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handleStateChange = async (e, setFieldValue) => {
    // const percentage = getPercentage(e.target.value);
    e.preventDefault();
    const state = e.target.value;

    // handleChange("state", state);

    setCurrentLocation(state);
    setFieldValue("state", state);

    // console.log(data);
    // if (data) {
    //   setCity(data.location.city);
    // }

    for (const _location of location) {
      if (_location._id.toString() === e.target.value.toString()) {
        setFieldValue("city", _location.city[0]._id);
      }
    }
  };

  console.log(city);

  return (
    <div className="container-relative">
      <SideBar />

      {loader ? (
        <Loader />
      ) : (
        <>
          {thisUser && (
            <div className="in-container">
              <div className="page-head mb-4">
                <div className="d-flex align-items-center">
                  <Link to="/settings" className="btn btn-icon">
                    <img src="/images/arrow-left.svg" alt="..." />
                  </Link>
                  <h4 className="ms-3">Edit profile</h4>
                </div>
              </div>
              <div className="my-profile card">
                <div className="banner-profile">
                  {/* {thisUser && thisUser.backgroundImage ? (
                <ImagePreview imageValue={thisUser.backgroundImage} />
              ) : (
                <img src="/images/3.jpg" alt="..." />
              )} */}

                  {!backgroundImage && !thisUser.backgroundImage ? (
                    <img src="/images/headerBg3.png" alt="..." />
                  ) : (
                    <ImagePreview
                      file={backgroundImage}
                      imageValue={thisUser.backgroundImage}
                    />
                  )}
                </div>
                <div className="my-profile-info">
                  <div className="big-profile">
                    <figure className="avatar">
                      {!image && !thisUser.image ? (
                        <img src="/images/user.jpg" alt="..." />
                      ) : (
                        <ImagePreview
                          file={image}
                          imageValue={thisUser.image}
                        />
                      )}
                    </figure>
                    {/* <a href="javascript:;" className="btn btn-icon">
                      <img src="/images/edit-03.svg" />
                    </a> */}
                    <div className="profile-upload">
                      <button className="btn btn-icon">
                        <img src="/images/edit-03.svg" />
                        <input
                          type="file"
                          name="image"
                          id="image"
                          // value={image}
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </button>
                    </div>
                  </div>

                  <div className="my-profile-name text-center mb-4">
                    <h4>{thisUser.fullName}</h4>
                    <p>{thisUser.briefIntro}</p>
                  </div>
                </div>
              </div>
              <Formik
                initialValues={{
                  fullName: thisUser.fullName || "",
                  userName: thisUser.userName || "",
                  mobile: thisUser.mobile || "",
                  // email: thisUser.fullName,
                  briefIntro: thisUser.briefIntro || "",
                  website: thisUser.website || "",
                  state:
                    (thisUser.location && thisUser.location.stateId) ||
                    currentLocation ||
                    location[0]._id ||
                    "",
                  city:
                    (thisUser.location && thisUser.location.cityId) ||
                    (city && city.length > 0 && city[0]._id) ||
                    "",
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  mobile: Yup.string()
                    .required("Mobile number required")
                    .matches(phoneRegExp, "Invalid mobile number")
                    .max(10),
                  fullName: Yup.string().required("Full name required"),
                  userName: Yup.string().required("User name required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    const formData = new FormData();

                    formData.append("fullName", values.fullName);
                    formData.append("userName", values.userName);
                    formData.append("mobile", values.mobile);
                    formData.append("briefIntro", values.briefIntro);
                    formData.append("website", values.website);
                    formData.append("stateId", values.state);
                    formData.append("cityId", values.city);
                    if (image) formData.append("image", image);
                    if (backgroundImage)
                      formData.append("backgroundImage", backgroundImage);

                    const response = await Patch(
                      formData,
                      "api/v2/app/user/update-user",
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    );
                    if (response && response.data.status) {
                      // console.log(response.data.user);
                      toast.success(response.data.message);
                    }
                    setStatus({ success: false });
                    setSubmitting(false);
                  } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form className="profile-form card" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Full Name</label>
                      <input
                        type="text"
                        name="fullName"
                        id="fullName"
                        className="form-control"
                        value={values.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.fullName && touched.fullName && (
                        <div className="invalid-feedback-custom">
                          {errors.fullName}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>User Name</label>
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        className="form-control"
                        value={values.userName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.userName && touched.userName && (
                        <div className="invalid-feedback-custom">
                          {errors.userName}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Brief Introduction</label>
                      <textarea
                        style={{ height: "100px" }}
                        className="form-control"
                        value={values.briefIntro}
                        onChange={handleChange}
                        id="briefIntro"
                        name="briefIntro"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label>Mobile number</label>
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        className="form-control"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.mobile && touched.mobile && (
                        <div className="invalid-feedback-custom">
                          {errors.mobile}
                        </div>
                      )}
                    </div>
                    {/* <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    value="joshua.hughes@google.com"
                  />
                </div> */}
                    {location.length > 0 && city.length > 0 && (
                      <div className="form-group">
                        <label>Select state</label>
                        <select
                          className="form-control"
                          id="state"
                          value={values.state}
                          label="state"
                          onChange={(e) => handleStateChange(e, setFieldValue)}
                        >
                          {location.map((_location, index) => (
                            <option
                              key={index}
                              id="state"
                              value={_location._id}
                            >
                              {_location.state}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {city.length > 0 && values.state !== "" && (
                      <>
                        <div className="form-group">
                          <label>Select city</label>
                          <select
                            className="form-control"
                            id="city"
                            // id="city"
                            value={values.city}
                            label="city"
                            onChange={handleChange}
                          >
                            {city.map((_city, index) => (
                              <option key={index} id="city" value={_city._id}>
                                {_city.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                    <div className="form-group">
                      <label>Your Website</label>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        value={values.website}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>

                    {/* <div className="form-group">
                  <label>Date of birth</label>
                  <input
                    type="date"
                    name=""
                    className="form-control"
                    value="18/01/2023"
                  />
                </div> */}
                    <div className="mt-2">
                      <button className="w-100 btn btn-blue" type="submit">
                        Update profile
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditProfile;
