import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";

const Comments = ({
  image,
  comments,
  addCommentHandler,
  feedId,
  replyHandler,
  commentDeleteHandler,
  mediaId,
  deleteReplyHandler,
  commentEditHandler,
  editingComment,
}) => {
  const [content, setContent] = useState("");
  const [reply, setReply] = useState("");

  return (
    <div className="post-comments">
      <div className="add-comment d-flex">
        <figure className="avatar">
          <Link to="/">
            {image ? (
              <img src={`${BASE_URL}${image}`} alt="..." />
            ) : (
              <img src="/images/default.jpeg" alt="..." />
            )}
          </Link>
        </figure>
        <figcaption>
          <div className="form-group send-input-btn">
            <input
              type="text"
              name="content"
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Add a comment…"
              className="form-control"
            />
            <button
              type="button"
              disabled={content.length === 0}
              onClick={() =>
                addCommentHandler(
                  feedId,
                  content,
                  setContent,
                  setReply,
                  null,
                  mediaId
                )
              }
            >
              {editingComment ? "Edit Comment" : "Add Comment"}
            </button>
          </div>
        </figcaption>
      </div>
      <ul>
        {comments.length > 0 &&
          comments.map((comment, index) => (
            <li key={index}>
              <div className="comment-box">
                <figure>
                  <Link to={`/profile/${comment.user._id}`} className="avatar">
                    {comment && comment.user && comment.user.image ? (
                      <img src={`${BASE_URL}${comment.user.image}`} alt="..." />
                    ) : (
                      <img src="/images/default.jpeg" alt="..." />
                    )}
                  </Link>
                </figure>
                <figcaption>
                  <div>
                    <h4>
                      <Link to={`/profile/${comment.user._id}`}>
                        {comment.user.fullName}
                      </Link>
                    </h4>
                    <p>{comment.content}</p>
                    <button
                      type="button"
                      onClick={() => replyHandler(feedId, comment._id, mediaId)}
                      className="reply-text"
                    >
                      Reply
                    </button>
                    {comment.showReply && comment.replies && (
                      <div className="reply-box">
                        <div className="form-group mb-0 mt-2 send-input-btn">
                          <input
                            type="text"
                            name="reply"
                            id="reply"
                            value={reply}
                            placeholder="Add a reply…"
                            className="form-control"
                            onChange={(e) => setReply(e.target.value)}
                          />
                          <button
                            type="button"
                            disabled={reply.length === 0}
                            onClick={() =>
                              addCommentHandler(
                                feedId,
                                reply,
                                setContent,
                                setReply,
                                comment._id,
                                mediaId
                              )
                            }
                          >
                            <img src="/images/send-03.svg" alt="..." />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="time-and-more">
                    <span>{moment(comment.createdAt).fromNow()}</span>
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src="/images/dots-vertical.svg" />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <button
                            type="button"
                            onClick={() =>
                              commentEditHandler(comment, setContent)
                            }
                            className="dropdown-item"
                          >
                            <img src="/images/edit-03.svg" alt="..." /> Edit
                            comment
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() =>
                              commentDeleteHandler(feedId, comment._id)
                            }
                            className="dropdown-item"
                          >
                            <img src="/images/trash-03.svg" alt="..." />
                            Delete comment
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </figcaption>
              </div>
              {comment.replies &&
                comment.replies.length > 0 &&
                comment.replies[0].content && (
                  <>
                    {comment.replies.map((reply, index) => (
                      <div className="comment-box comment-box-in" key={index}>
                        <figure>
                          <Link to={`/profile/${reply._id}`} className="avatar">
                            {reply && reply.user && reply.user.image ? (
                              <img
                                src={`${BASE_URL}${reply.user.image}`}
                                alt="..."
                              />
                            ) : (
                              <img src="/images/default.jpeg" alt="..." />
                            )}
                          </Link>
                        </figure>
                        <figcaption>
                          <div>
                            <h4>
                              <a href="javascript:;">{reply.user.fullName}</a>
                            </h4>
                            <p>{reply.content}</p>
                            {/* <a href="javascript:;" className="reply-text">
                              Reply
                            </a> */}
                          </div>
                          <div className="time-and-more">
                            <span>2 min ago</span>
                            <div className="dropdown">
                              <button
                                className="btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src="/images/dots-vertical.svg" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {/* <li>
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    href="#"
                                  >
                                    <img src="/images/edit-03.svg" /> Edit
                                    comment
                                  </button>
                                </li> */}
                                <li>
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    onClick={() =>
                                      deleteReplyHandler(comment._id, reply._id)
                                    }
                                  >
                                    <img src="/images/trash-03.svg" />
                                    Delete comment
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </figcaption>
                      </div>
                    ))}
                  </>
                )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Comments;
