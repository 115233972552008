import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Patch } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function EditPostModal({
  handleClickOpen,
  handleClose,
  open,
  setOpen,
  feed,
  editPostClickHandler,
}) {
  const initialState = {
    title: feed.title || "",
    description: feed.description || "",
  };

  const [currentState, setCurrentState] = useState(initialState);
  const { user, token } = useSelector((state) => state.user);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      sx={{ margin: "0px !important" }}
    >
      <DialogTitle>Edit Post</DialogTitle>
      <DialogContent sx={{ margin: "0px !important", maxWidth: "90vw" }}>
        <div className="create-post card edit-dialog">
          <div className="create-post-in d-flex">
            <div className="avatar">
              {/* {user.image ? (
                  <img src={`${BASE_URL}${user.image}`} alt="..." />
                ) : ( */}
              <img src="/images/default.jpeg" alt="..." />
              {/* )} */}
            </div>
            <div className="create-action w-100">
              <div className="form-group mb-0">
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control"
                  placeholder="Enter title"
                  value={currentState.title}
                  onChange={(event) =>
                    setCurrentState((prevState) => ({
                      ...prevState,
                      title: event.target.value,
                    }))
                  }
                />
              </div>
              <hr />
              <div className="form-group mb-0">
                <textarea
                  className="form-control"
                  placeholder="What's happening?"
                  value={currentState.description}
                  onChange={(event) =>
                    setCurrentState((prevState) => ({
                      ...prevState,
                      description: event.target.value,
                    }))
                  }
                ></textarea>
              </div>
            </div>
          </div>
          <DialogActions>
            <div className="create-post-footer">
              <div className="d-flex align-items-center">
                <div className="ms-auto">
                  <button
                    className="btn btn-blue"
                    type="button"
                    onClick={() => editPostClickHandler(feed._id, currentState)}
                  >
                    Edit post
                  </button>
                </div>
              </div>
            </div>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
}
