import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Post } from "../api";
import { BASE_URL } from "../constants";
// import ImagePreview from "./ImagePreview";
import MediaPreview from "./MediaPreview";
// import VideoPreview from "./VideoPreview";

const initialState = {
  title: "",
  description: "",
  images: [],
  video: [],
};

const CreatePost = () => {
  const { user, token } = useSelector((state) => state.user);

  const [postState, setPostState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const createPostHandler = async () => {
    // Post(`api/v2/app/feeds`);
    setLoading(true);

    const trimTitle = postState.title.trim();
    const trimDescription = postState.description;

    if (
      trimTitle.length === 0 ||
      (postState.description.length > 0 && trimDescription.trim().length === 0)
    ) {
      toast.warn(
        "Title or description cannot include leading and trailing spaces"
      );
      setLoading(false);
      setPostState(initialState);
      return;
    }

    const formData = new FormData();
    formData.append("title", postState.title);
    formData.append("description", postState.description);
    if (postState.images.length > 0) {
      postState.images.forEach((image) => {
        formData.append("image", image);
      });
    }

    // formData.append("title", postState.title);
    if (postState.video.length > 0) {
      formData.append("video", postState.video[0]);
    }

    // console.log(postState);
    const { data } = await Post(formData, `api/v2/app/feeds`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.status) {
      toast.success(data.message);
    }
    setLoading(false);
    setPostState(initialState);
  };

  return (
    <div className="create-post card">
      <div className="create-post-in d-flex">
        <div className="avatar">
          {user.image ? (
            <img src={`${BASE_URL}${user.image}`} alt="..." />
          ) : (
            <img src="/images/default.jpeg" alt="..." />
          )}
        </div>
        <div className="create-action w-100">
          <div className="form-group mb-0">
            <input
              type="text"
              name="title"
              id="title"
              className="form-control"
              placeholder="Enter title"
              value={postState.title}
              onChange={(event) =>
                setPostState((prevState) => ({
                  ...prevState,
                  title: event.target.value,
                }))
              }
            />
          </div>
          <hr />
          <div className="form-group mb-0">
            <textarea
              className="form-control"
              placeholder="What's happening?"
              value={postState.description}
              onChange={(event) =>
                setPostState((prevState) => ({
                  ...prevState,
                  description: event.target.value,
                }))
              }
            ></textarea>
          </div>
          <div class="upload-preview">
            <div class="row">
              <MediaPreview postState={postState} setPostState={setPostState} />
            </div>
          </div>
        </div>
      </div>
      <div className="create-post-footer">
        <div className="d-flex align-items-center">
          <div className="image-video-btn" style={{ cursor: "pointer" }}>
            <button style={{ cursor: "pointer" }}>
              <img
                src="/images/image-03.svg"
                alt="..."
                style={{ cursor: "pointer" }}
              />
              <input
                style={{ cursor: "pointer" }}
                type="file"
                name="image"
                multiple
                id="image"
                accept="image/*"
                onChange={(event) =>
                  setPostState((prevState) => ({
                    ...prevState,
                    images: [...event.target.files],
                  }))
                }
              />
            </button>
            <button>
              <img src="/images/video-recorder.svg" alt="..." />
              <input
                style={{ cursor: "pointer" }}
                type="file"
                name="video"
                id="video"
                accept="video/*"
                onChange={(event) =>
                  setPostState((prevState) => ({
                    ...prevState,
                    video: [...event.target.files],
                  }))
                }
              />
            </button>
          </div>
          <div className="ms-auto">
            <button
              className="btn btn-blue"
              type="button"
              onClick={createPostHandler}
              disabled={
                loading ||
                !(
                  postState.title.length > 0 &&
                  (postState.description.length > 0 ||
                    postState.images.length > 0 ||
                    postState.video.length > 0)
                )
              }
            >
              Share post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
