import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";
import { setHeaderStatus } from "../store/reducers/header";

const MainHeader = () => {
  const { user } = useSelector((state) => state.user);
  const { status } = useSelector((state) => state.header);

  const dispatch = useDispatch();

  const iconClickHandler = () => {
    dispatch(setHeaderStatus());
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container">
          <div className="header-in w-100 d-flex align-items-center">
            <Link className="navbar-brand" to="/">
              <img src="/images/logo.svg" alt="..." />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="profile-header d-flex align-items-center ms-auto">
                <figure className="avatar">
                  {user.image ? (
                    <img src={`${BASE_URL}${user.image}`} alt="..." />
                  ) : (
                    <img src="/images/default.jpeg" alt="..." />
                  )}
                </figure>
                <figcaption className="ms-2">
                  <figcaption className="ms-2">{user.fullName}</figcaption>
                </figcaption>
              </div>
              <div
                className={
                  status
                    ? "menu-mobile btn btn-icon active"
                    : "menu-mobile btn btn-icon"
                }
                onClick={iconClickHandler}
              >
                <i className="ri-menu-3-line"></i>
                <i className="ri-close-fill"></i>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainHeader;
