import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";

const Connections = ({ data, followHandler, currentList }) => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="follower"
        role="tabpanel"
        aria-labelledby="follower-tab"
      >
        <div className="ff-list">
          <ul>
            {data().length > 0 &&
              data().map((_user, index) => {
                console.log(_user.mobile);
                return (
                  <li key={index}>
                    <div className="row">
                      <div className="col">
                        <div className="post-profile">
                          <figure className="avatar">
                            <Link to={`/profile/${_user._id}`}>
                              {_user.image ? (
                                <img
                                  src={`${BASE_URL}${_user.image}`}
                                  alt="..."
                                />
                              ) : (
                                <img src="/images/default.jpeg" alt="..." />
                              )}
                            </Link>
                          </figure>
                          <figcaption>
                            <div>
                              <h4>
                                <Link to={`/profile/${_user._id}`}>
                                  {_user.fullName}
                                </Link>
                              </h4>
                            </div>
                          </figcaption>
                        </div>
                      </div>
                      {_user._id.toString() !== user._id.toString() && (
                        <>
                          {_user.followedByMe ? (
                            <div className="ms-auto w-auto">
                              <button
                                className="btn btn-gry"
                                onClick={() => followHandler(_user._id)}
                              >
                                Following
                              </button>
                            </div>
                          ) : (
                            <div className="ms-auto w-auto">
                              <button
                                className="btn btn-blue"
                                onClick={() => followHandler(_user._id)}
                              >
                                Follow
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
          {/* {console.log(data().length)} */}
          {data().length === 0 && (
            <div class="no-post">
              <div class="no-post-in text-center">
                <figure>
                  <img src="/images/no-user.svg" alt="..." />
                </figure>
                <h4>
                  {currentList === "followers"
                    ? "No followers"
                    : "No followings"}
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div
        className="tab-pane fade"
        id="following"
        role="tabfollowingpanel"
        aria-labelledby="following-tab"
      >
        <div className="ff-list">
          <ul>
            {following.length > 0 &&
              following.map((_user, index) => (
                <li key={index}>
                  <div className="row">
                    <div className="col">
                      <div className="post-profile">
                        <figure className="avatar">
                          <Link to={`profile/${_user}`}>
                            {_user.image ? (
                              <img
                                src={`${BASE_URL}${_user.image}`}
                                alt="..."
                              />
                            ) : (
                              <img src="/images/user.jpg" alt="..." />
                            )}
                          </Link>
                        </figure>
                        <figcaption>
                          <div>
                            <h4>
                              <Link to={`profile/${_user}`}>
                                {_user.fullName}
                              </Link>
                            </h4>
                          </div>
                        </figcaption>
                      </div>
                    </div>
                    {_user._id.toString() !== user._id.toString() && (
                      <>
                        {_user.followedByMe ? (
                          <div className="ms-auto w-auto">
                            <button
                              className="btn btn-gry"
                              onClick={() => followHandler(_user._id)}
                            >
                              UnFollow
                            </button>
                          </div>
                        ) : (
                          <div className="ms-auto w-auto">
                            <button
                              className="btn btn-blue"
                              onClick={() => followHandler(_user._id)}
                            >
                              Follow
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div> */}
    </div>
  );
};

export default Connections;
