// import { lazy } from "react";

// project import
// import Loadable from "components/Loadable";
// import MinimalLayout from "layout/MinimalLayout";
import { Navigate } from "react-router-dom";
// import ChangePassword from "pages/authentication/auth-forms/ChangePassword";
import Layout from "../Components/Layout";
import ContactUs from "../Pages/Main/ContactUs";
import EditProfile from "../Pages/Main/EditProfile";
import FollowFollowing from "../Pages/Main/FollowFollowing";
import Home from "../Pages/Main/Home";
import Notification from "../Pages/Main/Notification";
import PostDetail from "../Pages/Main/PostDetail";
// import { lazy } from "react";
import Profile from "../Pages/Main/Profile";
import Setting from "../Pages/Main/Setting";
import VerifiedRequest from "../Pages/Main/VerifiedRequest";
import Feedback from "../Pages/Main/Feedback";
import CMS from "../Pages/Main/CMS";

// const Home = lazy(() => import("../Pages/Main/Home"));

// render - login
// const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));

// const AuthForgotPassword = Loadable(
//   lazy(() => import("pages/authentication/ForgotPassword"))
// );
// const AuthResetPassword = Loadable(
//   lazy(() => import("pages/authentication/ResetPassword"))
// );

// ==============================|| AUTH ROUTING ||============================== //

const MainRoutes = (isLoggedIn) => {
  return {
    path: "/",
    element: isLoggedIn ? (
      <Layout isLoggedIn={isLoggedIn} />
    ) : (
      <Navigate to="/login" replace={true} />
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/profile/:userId?",
        element: <Profile />,
      },
      {
        path: "/notification",
        element: <Notification />,
      },
      {
        path: "/settings",
        element: <Setting />,
      },
      {
        path: "/edit-profile",
        element: <EditProfile />,
      },
      {
        path: "/verification-account",
        element: <VerifiedRequest />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/connections/:userId/:tab?",
        element: <FollowFollowing />,
      },
      {
        path: "/post/:postId",
        element: <PostDetail />,
      },
      {
        path: "/feedback",
        element: <Feedback />,
      },
      {
        path: "/cms",
        element: <CMS />,
      },
    ],
  };
};

export default MainRoutes;
